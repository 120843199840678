import React, { useEffect, useState } from "react";
import H2 from "components/headings/H2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "contexts/Auth/AuthContext";
import "react-tooltip/dist/react-tooltip.css";
import { useForm } from "react-hook-form";
import { AuthLoginType } from "types/Auth.type";
import { ButtonDefault, ButtonPrimary, Input, Label } from "components/form";
import { Spinner } from "components/spinner/Spinner";
import AuthService from "services/auth.service";
import PaymentAccountService from "services/paymentAccount.service";
import customToast from "components/Toast/ToastSuccess";
import { LoadingIndicator } from "components/spinner/LoadingIndicator";
import { Tab } from "@headlessui/react";
import StripeImage from "assets/images/stripe.png";
import Check from "assets/images/check.png";
import Cross from "assets/images/cross.png";
import { Modal } from "antd";
import "./accountPage.scss";
import { PaymentAccountStatus } from "types/PaymentAccount.type";

const AccountPage = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams?.get("tab");
  const connected = searchParams?.get("connected");

  const auth = useAuth();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    watch,
  } = useForm<AuthLoginType>({
    defaultValues: {
      firstName: auth?.user?.firstName,
      email: auth?.user?.email,
    },
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [connectedAccountStatus, setConnectedAccountStatus] =
    useState<PaymentAccountStatus>();
  const [openNotification, setOpenNotification] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false);

      PaymentAccountService.getStatus().then((result) => {
        setConnectedAccountStatus(result.data);
        if (connected && result) {
          setOpenNotification(true);
          tabChange(1);
        }

        auth.update({
          paymentAccountId: result.data.id,
          paymentAccountStatus: result.data.status,
        });
      });
    }, 500);
  }, []);

  const watchFirstName = watch("firstName");
  const onSubmit = async ({ firstName }: any) => {
    try {
      setLoading(true);
      await AuthService.updateProfileByUserId(auth.user.userId, {
        firstName,
      });
      auth.update({
        firstName,
        userId: auth.user.userId,
        email: auth?.user?.email,
      });
      setLoading(false);
      customToast.success(`Your account is updated successfully`);
    } catch {
      setLoading(false);
      customToast.error(`Your account is updated failure`);
    }
  };

  useEffect(() => {
    switch (tab) {
      case "profile":
        setSelectedTabIndex(0);
        break;
      case "stripe":
        setSelectedTabIndex(1);
        break;
      default:
        setSelectedTabIndex(0);
    }
  }, [tab]);

  const tabChange = (index: number) => {
    if (index == 0) {
      navigate(`/account?tab=profile`);
    }
    if (index == 1) {
      navigate(`/account?tab=stripe`);
    }
  };

  const registerStripeAccount = async () => {
    setLoading(true);
    try {
      const paymentAccountUrl = window.location.href;
      const registerUrl = await PaymentAccountService.createConnectedLink({
        refreshUrl: paymentAccountUrl,
        returnUrl: `${paymentAccountUrl}&connected=true`,
      });
      window.location.href = registerUrl.data;
    } catch {
      setLoading(false);
    }
  };

  const goToDashBoard = async () => {
    if (connectedAccountStatus?.status === "Restricted") {
      registerStripeAccount();
      return;
    }

    window.location.href = "https://dashboard.stripe.com";
  };

  return pageLoading ? (
    <LoadingIndicator></LoadingIndicator>
  ) : (
    <>
      <div className="accountPage px-4 md:px-0">
        <H2>Account</H2>
        <div className="w-full lg:w-3/4 mt-[29px] tabs lg:pr-4">
          <Tab.Group selectedIndex={selectedTabIndex} onChange={tabChange}>
            <Tab.List className="flex">
              <Tab className="font-bold text-base">Profile</Tab>
              <Tab className="font-bold text-base">Stripe Connect</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="flex items-center my-[35px] px-4 lg:px-0">
                  <form
                    className="w-full md:max-w-[320px]"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="mb-[29px]">
                      <Label name="Name" />
                      <Input
                        control={control}
                        name="firstName"
                        rules={{
                          required: "This is required.",
                        }}
                        placeholder="Type your name here"
                        type="text"
                      />
                    </div>
                    <div className="mb-[29px]">
                      <Label name="Email" />
                      <Input
                        control={control}
                        name="email"
                        disabled={true}
                        rules={{
                          required: "This is required.",
                        }}
                        placeholder="Type your name here"
                        type="text"
                      />
                    </div>
                    <div className="mt-6 flex">
                      <ButtonPrimary
                        size="small"
                        type="submit"
                        styles="ml-auto md:ml-0 lg:ml-auto"
                        disabled={auth.user.firstName == watchFirstName?.trim()}
                      >
                        <div className="flex items-center justify-center">
                          {loading && <Spinner />}
                          <span>{(loading && "Please wait...") || "Save"}</span>
                        </div>
                      </ButtonPrimary>
                    </div>
                  </form>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="flex flex-col lg:w-[480px] px-4 lg:px-0">
                  <div>
                    <span className="font-bold text-lg">
                      Connect to your Stripe account
                    </span>
                  </div>
                  <div className="mt-4">
                    <img src={StripeImage} alt="" className="w-auto" />
                  </div>
                  {!auth?.user?.paymentAccountId ? (
                    <div className="text-base mt-4 text-justify">
                      We use Stripe to handle payments. Connect to your Stripe
                      account to start receiving payments.
                    </div>
                  ) : (
                    <>
                      <div className="text-base mt-4 text-justify">
                        Your Stripe account has been connected to Menubly.
                      </div>
                      {!auth.isStripeAccountConnected() && (
                        <>
                          <div className="text-justify">
                            <span style={{ color: "red" }}>
                              However, you have not completed setting up your
                              Stripe account to receive payment.
                            </span>
                            <span>
                              &nbsp;Please complete your setup on Stripe to
                              start receiving payment from your customers.
                            </span>
                          </div>
                          <div className="text-base mt-4 text-justify">
                            Having trouble setting up? Check out{" "}
                            <a
                              href="https://support.stripe.com"
                              target="_blank"
                              rel="noreferrer"
                              className="underline text-black"
                            >
                              Stripe Support
                            </a>{" "}
                            or email us at support@menubly.com
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {!auth?.user?.paymentAccountId ? (
                    <div className="flex justify-between mt-4">
                      <a className="mt-auto mb-auto" href="https://stripe.com/">
                        What is Stripe?
                      </a>
                      <ButtonPrimary
                        size="small"
                        styles="ml-auto md:ml-0 lg:ml-auto"
                        onClick={() => registerStripeAccount()}
                      >
                        <div className="flex items-center justify-center">
                          {loading && <Spinner />}
                          <span>
                            {(loading && "Please wait...") ||
                              "Connect to Stripe"}
                          </span>
                        </div>
                      </ButtonPrimary>
                    </div>
                  ) : (
                    <div className="flex justify-between mt-4">
                      <div></div>
                      <ButtonPrimary
                        size="small"
                        styles="ml-auto md:ml-0 lg:ml-auto"
                        onClick={() =>
                          (auth.isStripeAccountConnected() || auth.isStripeAccountCompletedOnboarding())
                            ? goToDashBoard()
                            : registerStripeAccount()
                        }
                      >
                        <div className="flex items-center justify-center">
                          {loading && <Spinner />}
                          <span>
                            {(loading && "Please wait...") ||
                              (auth.isStripeAccountConnected()
                                ? "Manage Stripe Settings"
                                : "Continue Setting up Stripe")}
                          </span>
                        </div>
                      </ButtonPrimary>
                    </div>
                  )}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      <Modal
        className="stripe-connect"
        title="Stripe connection successful"
        onCancel={() => setOpenNotification(false)}
        open={openNotification && auth.isStripeAccountConnected()}
        footer={[
          <ButtonPrimary
            key="guide"
            onClick={() =>
              window.open(
                "https://menubly.usetiful.help/article/0191740d-c1c7-6606-ce5a-67bc98c1a019",
                "_blank"
              )
            }
          >
            Go to Stripe Payment Setting Guides
          </ButtonPrimary>,
          <ButtonDefault key="close" onClick={() => setOpenNotification(false)}>
            Close
          </ButtonDefault>,
        ]}
      >
        <div className="my-8">
          <img src={Check} alt="" className="w-auto mx-auto" />
        </div>
        <p className="antd-model-desc">
          Connection successful! Your payment gateway with Stripe is now set up
          and ready to process transactions.
        </p>
      </Modal>
      <Modal
        className="stripe-connect"
        title="Fail to set up your Stripe Connect!"
        onCancel={() => setOpenNotification(false)}
        open={
          openNotification 
          && (auth.isStripeAccountCompletedOnboarding() || auth.isStripeAccountRestricted())
        }
        footer={[
          <ButtonPrimary key="guide" onClick={() => auth.isStripeAccountCompletedOnboarding() ? goToDashBoard() : registerStripeAccount()}>
            {loading && <Spinner />}
            <span>{(loading && "Please wait...") || "Try again"}</span>
          </ButtonPrimary>,
          <ButtonDefault key="close" onClick={() => setOpenNotification(false)}>
            Try later
          </ButtonDefault>,
        ]}
      >
        <div className="my-8">
          <img src={Cross} alt="" className="w-auto mx-auto" />
        </div>
        <p className="antd-model-desc">
          Your setup with Stripe Connect hasn&apos;t been completed. Please try
          again or email{" "}
          <a href="mailto:support@menubly.com">support@menubly.com</a> for help
          if the issue still persists.
        </p>
      </Modal>
    </>
  );
};

export default AccountPage;
