import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface Item {
    id?: string | number,
    menuItemId: string;
    name: string;
    orderId: number;
    price: number;
    quantity?: number;
    total?: number;
}

export interface Orders {
    createdDate: string;
    currency?: string,
    customerName: string,
    customerPhone?: string;
    id?: string | number;
    items?: Item[];
    orderNumber: number,
    orderStatus: number | null,
    paymentStatus: number | null;
    totalPrice: number | null;
    deliveryMode: number | null;
    pickupAddress: string;
    deliveryAddress: string;
    paymentId: string | null;
}

export interface TotalOrder {
    orders: Orders[],
    totalItems?: number | null;
    totalPages?: number | null;
    isLoading: boolean,
    orderDetails: Orders | null
}

const initialState: TotalOrder = {
    orders: [],
    totalItems: null,
    totalPages: null,
    isLoading: false,
    orderDetails: null
}

export const OrderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrder: (state, action: PayloadAction<{data: Orders[], totalItems: number, totalPages: number }>) =>  {
                return {
                    ...state,
                    orders: action.payload.data,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages
                }
        },
        updateStatus:  (state, action: PayloadAction<{ id: number, updatedOrder: Orders }>) => {
                return {
                    ...state,
                    orders: state.orders.map(order => order.id === action.payload.id ? action.payload.updatedOrder : order),
                    orderDetails: action.payload.updatedOrder
                }
        },
        orderDetail:  (state, action: PayloadAction< Orders >) => {
            return {
                ...state,
                orderDetails: action.payload
            }
    },
    }
})

export const {
    setOrder,
    updateStatus,
    orderDetail
} = OrderSlice.actions

export default OrderSlice.reducer